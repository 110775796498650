<template>
  <a-modal
    title="减免申请"
    v-model="visible"
    width="600px"
    :confirmLoading="btnLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="备注">
        <a-textarea placeholder="请输入备注" v-model="form.remark" :rows="4" />
      </a-form-model-item>
      <a-form-model-item label="附件上传">
        <a-upload
          v-if="!form.file"
          name="file"
          :showUploadList="false"
          :accept="accept"
          :beforeUpload="handleBefore"
          :customRequest="handleCustomRequest"
        >
          <a-button type="primary"> 上传 </a-button>
        </a-upload>
        <template v-else>
          <div class="file-wrp">
            <span
              class="file-name"
            >{{ form.file && (form.file.fileName || form.file.imageName) }}.{{ form.file && form.file.extName }}</span
            >
            <a-icon type="delete" @click="form.file = null" style="color: #40a9ff; margin-left: 24px" />
          </div>
        </template>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { uploadFile, uploadImage } from '@/api/materialShop'
import { addApply, updateApply } from '@/api/recruitUse'

export default {
  name: 'ApplyModal',
  inject: ['pageType'],
  data () {
    return {
      visible: false,
      btnLoading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      form: {
        remark: '',
        file: null
      },
      fileType: ['.pdf', '.docx', '.png', '.jpg'],
      applyId: false
    }
  },
  computed: {
    accept () {
      return this.fileType.join(',')
    }
  },
  methods: {
    showModal (applyId) {
      this.visible = true
      this.applyId = applyId
    },
    handleSubmit () {
      const fun = this.applyId ? updateApply : addApply
      this.btnLoading = true
      fun({
        remark: this.form.remark,
        file: this.form.file ? this.form.file.path : '',
        status: 0,
        flag: this.pageType,
        enterpriseId: this.$store.getters.enterpriseId,
        enterpriseName: this.$store.getters.enterpriseName,
        [this.applyId ? 'id' : '']: this.applyId
      })
        .then((res) => {
          this.handleCancel()
          this.$message.success('申请提交成功')
        })
        .finally(() => {
          this.btnLoading = false
        })
    },
    handleCancel () {
      this.visible = false
      this.form = {
        remark: '',
        file: null
      }
    },
    handleGetFileType ({ name }) {
      const nameSplitArr = name.split('.')
      const fileType = nameSplitArr[nameSplitArr.length - 1]
      return fileType
    },
    handleBefore (file) {
      const fileType = this.handleGetFileType(file)
      const result = this.fileType.some((item) => item.includes(fileType.toLowerCase()))
      if (!result) {
        this.$message.error(`文件只支持${this.accept}格式，请重新选择上传`)
      }
      return result
    },
    handleCustomRequest (file) {
      this.$spin.show()
      const formData = new FormData()
      formData.append('file', file.file)
      const fileType = this.handleGetFileType(file.file)
      const fun = ['.pdf', '.docx'].some((item) => item.includes(fileType.toLowerCase())) ? uploadFile : uploadImage
      fun(formData)
        .then((res) => {
          this.form.file = res
        })
        .finally(() => {
          this.$spin.hide()
        })
    }
  }
}
</script>
