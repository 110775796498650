<template>
  <a-modal
    title="薪资数据"
    v-model="visible"
    width="1300px"
    :confirmLoading="btnLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <div class="modal-tips">
      <div class="tips" style="color: red">*工资条需满足【工资发放计算公式】方可上传</div>
      <div class="modal-tips-item">
        <span style="color: red">*</span>工资发放计算公式：税前工资金额+增值税及附加税{{ isServe ? '+平台服务费' : '' }}
        <a-popover placement="rightTop" overlayClassName="tax-popo">
          <template slot="content">
            <div class="popover-tax">
              <div class="popo-col">
                <span class="popo-col-row">费用类别</span>
                <span class="popo-col-row">增值税</span>
                <span class="popo-col-row">附加税</span>
                <span class="popo-col-row">服务费</span>
              </div>
              <div class="popo-col">
                <span class="popo-col-row">{{ isServe ? '市场费率' : '费率' }}</span>
                <span
                  :class="{
                    'popo-col-row': true,
                    'popo-col-row-line': !!taxData.tax.self,
                  }"
                >{{ taxData.tax.other | isEmpty }}</span
                >
                <span
                  :class="{
                    'popo-col-row': true,
                    'popo-col-row-line': !!taxData.addTax.self,
                  }"
                >{{ taxData.addTax.other | isEmpty }}</span
                >
                <span
                  :class="{
                    'popo-col-row': true,
                    'popo-col-row-line': !!taxData.serveTax.self,
                  }"
                >{{ taxData.serveTax.other | isEmpty }}</span
                >
              </div>
              <div class="popo-col" v-if="isServe">
                <span class="popo-col-row">优惠费率</span>
                <span class="popo-col-row">{{ taxData.tax.self | isEmpty }}</span>
                <span class="popo-col-row">{{ taxData.addTax.self | isEmpty }}</span>
                <span class="popo-col-row">{{ taxData.serveTax.self | isEmpty }}</span>
              </div>
            </div>
          </template>
          <a-icon type="question-circle" style="margin-left: 8px" />
        </a-popover>
      </div>
      <div class="modal-tips-content">
        <div class="modal-tips-item">本次工资发放工资总金额：{{ currentTotal }}元</div>
        <div class="modal-tips-line"></div>
        <div class="modal-tips-item">
          成功发放工资所需企业余额{{ isServe ? '（含平台手续费）' : '' }}：{{ enterTotal }}元
        </div>
      </div>
      <div class="modal-tips-content">
        <div class="modal-tips-item">当前企业余额：{{ enterpriseMoney }}元</div>
        <div class="modal-tips-line"></div>
        <div class="modal-tips-item">
          还需充值：<span style="color: #40a9ff">{{ payNumber }}</span
          >元即可成功发薪
          <a-button style="margin-left: 12px" size="small" type="primary" @click="handleToPay">立即充值</a-button>
        </div>
      </div>
    </div>
    <div class="content_tips">
      结算周期：
      <a-range-picker
        v-model="date"
        :disabledDate="disabledDate"
        @change="handleChangeDate"
        value-format="YYYY-MM-DD"
      />
    </div>
    <a-table :columns="columns" :data-source="tableData" :pagination="false" rowKey="rowId">
      <template
        v-for="col in ['day', 'payable', 'deduction', 'workOvertime', 'workOvertimePayment']"
        :slot="col"
        slot-scope="text, record, index"
      >
        <div :key="col">
          <a-input-number
            :precision="col === 'day' ? 1 : 2"
            :value="text"
            :min="0"
            @change="(value) => handleChange(value, record.rowId, col)"
          />
        </div>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import { addBatchCommission, addCommission } from '@/api/recruitUse'
import { queryBalance } from '@/api/balance'
import moment from 'moment'

export default {
  name: 'SalyModal',
  inject: ['isServe', 'pageType'],
  filters: {
    isEmpty: (t) => {
      return t ? `${t}%` : '-'
    }
  },
  data () {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'key',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '工人姓名',
          dataIndex: 'realName',
          width: 100
        },
        {
          title: '注册账号',
          dataIndex: 'userName',
          width: 100
        },
        {
          title: '联系电话',
          dataIndex: 'phone',
          width: 100
        },
        {
          title: '出勤（天）',
          dataIndex: 'day',
          width: 100,
          scopedSlots: { customRender: 'day' }
        },
        {
          title: '应发（元）',
          dataIndex: 'payable',
          width: 100,
          scopedSlots: { customRender: 'payable' }
        },
        {
          title: '加班（小时）',
          dataIndex: 'workOvertime',
          width: 100,
          scopedSlots: { customRender: 'workOvertime' }
        },
        {
          title: '加班费用（元）',
          dataIndex: 'workOvertimePayment',
          width: 120,
          scopedSlots: { customRender: 'workOvertimePayment' }
        },
        {
          title: '扣款（元）',
          dataIndex: 'deduction',
          width: 100,
          scopedSlots: { customRender: 'deduction' }
        },
        {
          title: '实发（元）',
          dataIndex: 'realPay',
          width: 100,
          scopedSlots: { customRender: 'realPay' }
        },
        {
          title: '结算周期',
          dataIndex: 'date',
          width: 240
        }
      ],
      tableData: [],
      visible: false,
      btnLoading: false,
      enterpriseMoney: 0,
      date: [],
      isExport: false,
      taxList: [
        {
          isServe: true,
          pageType: 1,
          tax: { other: 6, self: null },
          addTax: { other: 0.72, self: null },
          serveTax: { other: 15, self: 5 }
        },
        {
          isServe: false,
          pageType: 1,
          tax: { other: 6, self: null },
          addTax: { other: 0.72, self: null },
          serveTax: { other: null, self: null }
        },
        {
          isServe: true,
          pageType: 2,
          tax: { other: 6, self: null },
          addTax: { other: 0.72, self: null },
          serveTax: { other: 15, self: 5 }
        },
        {
          isServe: false,
          pageType: 2,
          tax: { other: 6, self: null },
          addTax: { other: 0.72, self: null },
          serveTax: { other: null, self: null }
        }
      ]
    }
  },
  computed: {
    taxData () {
      return this.taxList.find((item) => item.isServe === this.isServe && item.pageType == this.pageType)
    },
    serveTarif () {
      if (this.taxData) {
        const { tax, addTax, serveTax } = this.taxData
        const taxNum = tax.self || tax.other || 0
        const addTaxNum = addTax.self || addTax.other || 0
        const serveTaxNum = serveTax.self || serveTax.other || 0
        // return 1 + (taxNum + addTaxNum + serveTaxNum) / 100
        return {
          serveTaxNum: serveTaxNum / 100,
          taxNums: (this.accAdd(taxNum, addTaxNum)) / 100
        }
      }
      return 0
    },
    currentTotal () {
      const total = this.tableData.reduce((total, item) => {
        return this.accAdd(total, item.realPay || 0)
      }, 0)
      return Math.round(total * 100) / 100
    },
    enterTotal () {
      let sum = 0
      const { serveTaxNum, taxNums } = this.serveTarif
      console.log(serveTaxNum, taxNums)
      this.tableData.forEach((item) => {
        if (item.realPay) {
          const sernum = Math.round(item.realPay * serveTaxNum * 100) / 100
          const taxNum = Math.round(item.realPay * taxNums * 100) / 100
          sum = this.accAdd(sum, sernum)
          sum = this.accAdd(sum, taxNum)
          sum = this.accAdd(sum, Number(item.realPay))
        }
      })
      return Math.round(sum * 100) / 100
      // return Math.round(this.accMul(this.currentTotal, this.serveTarif) * 100) / 100
    },
    payNumber () {
      if (this.enterpriseMoney < this.enterTotal) {
        return this.accSubtr(this.enterTotal, Number(this.enterpriseMoney))
      }
      return 0
    }
  },
  methods: {
    open (data = [], isExport = false) {
      queryBalance({
        enterpriseId: this.$store.getters.userInfo?.enterpriseInfoDto?.enterpriseId
      }).then((res) => {
        this.enterpriseMoney = res?.data?.money || '0.00'
      })
      this.tableData = data.map((item, index) => ({
        ...item,
        rowId: index
      }))
      this.visible = true
      this.isExport = isExport
    },
    handleCancel () {
      this.visible = false
      this.btnLoading = false
      this.date = []
      this.tableData = []
    },
    handleResult () {
      return this.tableData.reduce(
        (result, item, arr, index) => {
          const { day, payable, deduction, realPay, workOvertime, workOvertimePayment, realName } = item
          const isEmpty = (number) => ![undefined, null, ''].includes(number)
          if (
            isEmpty(day) &&
            isEmpty(payable) &&
            isEmpty(deduction) &&
            isEmpty(workOvertime) &&
            isEmpty(workOvertimePayment) &&
            isEmpty(realPay)
          ) {
            if (realPay <= 0) {
              result.error.push(realName)
              return result
            }
            if (realPay == this.accAdd(this.accSubtr(payable || 0, deduction || 0), workOvertimePayment || 0)) {
              return result
            }
            result.result.push(realName)
            return result
          }
          result.required.push(realName)
          return result
        },
        { required: [], result: [], error: [] }
      )
    },
    handleChange (value, key, column) {
      const newData = [...this.tableData]
      const target = newData.filter((item) => key === item.rowId)[0]
      if (target) {
        target[column] = value
        if (['payable', 'deduction', 'workOvertime', 'workOvertimePayment'].includes(column)) {
          target.realPay = this.accAdd(
            this.accSubtr(target.payable || 0, target.deduction || 0),
            target.workOvertimePayment || 0
          )
        }
        this.tableData = newData
      }
    },
    disabledDate (current) {
      // Can not select days before today and today
      return current && current > moment().endOf('day')
    },
    handleSubmit () {
      if (!this.isExport && (!this.date || !this.date.length)) {
        this.$message.warning('请输入结算周期')
        return
      }
      const result = this.handleResult()
      const resultMessage = result.result.join(',')
      const requiredMessage = result.required.join(',')
      const errorMessage = result.error.join(',')
      if (resultMessage || requiredMessage || errorMessage) {
        this.$message.warning({
          content: (h) => (
            <span>
              {requiredMessage && <span>【{requiredMessage}】工资数据请填写完整</span>}
              {errorMessage && <span>【{errorMessage}】实发工资不能小于0</span>}
              {resultMessage && <span>【{resultMessage}】工资数据应发（元）- 扣款（元）≠ 实发（元）</span>}
            </span>
          )
        })
      } else {
        const submitFun = this.tableData.length > 1 ? addBatchCommission : addCommission
        const model =
          this.tableData.length > 1
            ? this.tableData.map((item) => ({ ...item, flag: this.pageType, taxStatus: this.isServe ? 1 : 0 }))
            : {
                ...this.tableData[0],
                flag: this.pageType,
                taxStatus: this.isServe ? 1 : 0
              }
        this.btnLoading = true
        submitFun(model)
          .then(() => {
            this.$message.success('薪资数据上传成功')
            this.$eventBus.$emit('success')
            this.handleCancel()
          })
          .finally(() => {
            this.btnLoading = false
          })
      }
    },
    handleToPay () {
      window.open('/home/balance?isRecharge=true')
    },
    handleChangeDate (value = []) {
      this.tableData.forEach((item) => {
        item.date = value.join('-')
      })
    }
  }
}
</script>

<style lang="less">
.modal-tips {
  font-weight: 550;
  &-item {
    margin-bottom: 5px;
  }
  &-content {
    display: flex;
    align-items: center;
  }
  .tips {
    color: red;
    margin-bottom: 5px;
  }
  &-line {
    height: 14px;
    width: 1px;
    background: rgba(0, 0, 0, 0.65);
    margin: -2px 12px 2px;
  }
}
.tax-popo {
  .ant-popover-content {
    .ant-popover-arrow {
      border-left-color: rgba(0, 0, 0, 0.8);
      border-bottom-color: rgba(0, 0, 0, 0.8);
    }
    .ant-popover-inner {
      background: rgba(0, 0, 0, 0.8);
    }
  }
}
.popover-tax {
  display: flex;
  align-items: center;
  width: 270px;
  color: #ffffff;
  .popo-col {
    flex: 1;
    text-align: center;
    .popo-col-row {
      display: block;
      margin: 5px 0;
      &-line {
        text-decoration-line: line-through;
      }
    }
  }
}
</style>
