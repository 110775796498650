<template>
  <a-modal
    title="备注"
    v-model="visible"
    width="600px"
    :confirmLoading="btnLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="备注">
        <a-textarea placeholder="请输入备注" v-model="form.notes" :rows="4" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

  <script>
  import { updateSalary } from '@/api/recruitUse'

  export default {
    name: 'ApplyModal',
    data () {
      return {
        visible: false,
        btnLoading: false,
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
        form: {
          notes: ''
        }
      }
    },
    methods: {
      showModal (rowItem) {
        this.visible = true
        this.form = { ...rowItem }
      },
      handleSubmit () {
        this.btnLoading = true
        updateSalary({
            ...this.form,
            id: this.form.id
        })
          .then(() => {
            this.$message.success('备注编辑成功')
            this.$emit('success')
            this.handleCancel()
          })
          .finally(() => {
            this.btnLoading = false
          })
      },
      handleCancel () {
        this.form = {}
        this.visible = false
      }
    }
  }
  </script>
